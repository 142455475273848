
import ProductCard from "~/components/common/ProductCard";
import EntityCard from "@/components/common/EntityCard";
import GridCard from "@/components/common/GridCard";
import googleAnalyticsEvents from "~/mixins/googleAnalyticsEvents";
import UiSwiperCarousel from "~/components/UI/UiSwiperCarousel.vue";
export default {
  name: "EntityBlock",
  components: {
    UiSwiperCarousel,
    ProductCard,
    EntityCard,
    GridCard,
  },
  mixins: [googleAnalyticsEvents],
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    blockData: {
      type: Array,
      default: () => [],
    },
    pageData: {
      type: Object,
      default: () => {},
    },
    block: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      resData: null,
      entityResponse: null,
      activeTab: 0,
      activeSlideIdx: 0,
    };
  },
  async fetch() {
    await this.getInfo();
    if (this.data.tabsView && this.data.productView) {
      await this.getEntities();
    }
  },
  computed: {
    canSendGoogleAnalyticsStatistic() {
      return (
        this.integrationCallbackStatuses.TYPE_GOOGLE_ANALYTICS &&
        this.mapedData?.length
      );
    },
    statisticItemListName() {
      return this.pageData?.mixedTranslation?.title || this.data?.title;
    },
    ids() {
      return this.data.entities?.reduce((acc, el) => {
        if (el.active && el.id) {
          acc.push(el.id);
        }
        return acc;
      }, []);
    },
    catalogQueryString() {
      const entities = this.data?.entities ?? [];
      const temp = entities.reduce((acc, current) => {
        if (current.active) {
          acc.push(current.id);
        }
        return acc;
      }, []);
      return { [String(this.data.type)]: temp, count: 20 };
    },
    tabsData() {
      if (
        this.data.tabsView &&
        this.data.productView &&
        this.entityResponse &&
        this.resData
      ) {
        return this.entityResponse?.data.reduce(
          (acc, el) => {
            const tab = {
              id: el.id,
              label: el.mixedTranslation?.title || el.title,
              items: this.groupProducts(this.resData?.data || [], el), // TODO
            };
            acc.push(tab);
            return acc;
          },
          [
            {
              id: 0,
              label: this.$t("all"),
              items: this.resData?.data || [],
            },
          ]
        );
      }
      return null;
    },
    mapedData() {
      let temp = [];
      if (!this.data.productView) {
        temp =
          this.resData?.data?.reduce((acc, el) => {
            const tempEl = this.data.entities?.find(
              (item) => item.id === el.id
            );
            if (tempEl && tempEl.active) {
              acc.push({
                ...el,
                ...tempEl,
              });
            }
            return acc;
          }, []) || [];
        temp.sort((a, b) => a.sort_order - b.sort_order);
      } else if (this.data.productView && this.data.tabsView) {
        temp = this.tabsData?.[this.activeTab]?.items || [];
      } else {
        temp = this.resData?.data || [];
      }
      return temp;
    },
    isStonesBlock() {
      return this.block?.name === "stones-entity";
    },
  },
  watch: {
    canSendGoogleAnalyticsStatistic: {
      handler() {
        if (this.data.productView) {
          this.gaViewItemListEvent(this.mapedData, {
            item_list_name: this.statisticItemListName,
          });
        }
      },
      immediate: true,
    },
  },
  methods: {
    async getInfo() {
      try {
        const { data } = this.data.productView
          ? await this.$api.products.getCatalog(this.catalogQueryString)
          : await this.$api.products.getEntities(this.data?.type || "", {
              ids: this.ids,
              count: 9999999,
            });
        this.resData = data;
      } catch (err) {
        console.log(err);
      }
    },
    async getEntities() {
      try {
        const { data } = await this.$api.products.getEntities(
          this.data?.type || "",
          { ids: this.ids }
        );
        this.entityResponse = data;
      } catch (err) {
        console.log(err);
      }
    },
    groupProducts(items, tab) {
      if (!items.length) {
        return [];
      }
      if (this.data.type === "materials") {
        return items.filter((el) => el.materials?.some((i) => i.id === tab.id));
      } else if (this.data.type === "collections") {
        return items.filter((el) => el.collections_ids.includes(tab.id));
      } else if (this.data.type === "categories") {
        return items.filter((el) => el.categories_ids.includes(tab.id));
      }
    },
  },
};
