
import CardImageView from "~/components/common/CardImageView";
import { getImageByPosition, setAttributeToElements } from "~/utils";

export default {
  name: "EntityCard",
  components: {
    CardImageView,
  },
  props: {
    data: {
      type: Object,
      default: () => ({ path: "/product/conf" }),
    },
    contentPosition: {
      type: String,
      default: "left",
    },
    imageBrightnessFilter: {
      type: [String, Number],
      default: 100,
    },
    contain: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    itemImage() {
      const imageObj = getImageByPosition(this.data.medias, "list");
      return {
        url: imageObj?.file?.url || "",
        alt: imageObj?.alt || "",
      };
    },
  },
  mounted() {
    setAttributeToElements("a.hidden-ssr-link", "tabindex", "0");
  },
};
